<template>
  <div class="warehouse">
    <el-card>
      <el-row>
        <el-col :span="8">
          <div ref="chart1" style="width: 100%;height:297px;"></div>
        </el-col>
        <el-col :span="8">
          <div ref="chart2" style="width: 100%;height:297px;"></div>
        </el-col>
        <el-col :span="8">
          <div ref="chart3" style="width: 100%;height:297px;"></div>
        </el-col>
      </el-row>
      <el-table v-loading="tableLoading" :data="tableData" style="width: 100%;" border @sort-change="sort_change" show-summary :summary-method="getSummaries">
        <el-table-column header-align="center" align="center" prop="tenantCode" label="仓库编号"></el-table-column>
        <el-table-column header-align="center" align="center" prop="tenantName" label="仓库名称"></el-table-column>
        <el-table-column header-align="center" align="center" prop="ownerCount" label="货主数量"></el-table-column>
        <el-table-column header-align="center" align="center" prop="productSkuCount" label="产成品SKU数"></el-table-column>
        <el-table-column header-align="center" width="166" align="center" prop="productInventory" label="产成品库存量" sortable="custom"></el-table-column>
        <el-table-column header-align="center" align="center" prop="teaMaterialSkuCount" label="散茶SKU数"></el-table-column>
        <el-table-column header-align="center" width="166" align="center" prop="teaMaterialInventory" label="散茶库存量" sortable="custom"></el-table-column>
        <el-table-column header-align="center" align="center" prop="semiManufactureSkuCount" label="准成品SKU数"></el-table-column>
        <el-table-column header-align="center" width="166" align="center" prop="teaMaterialInventory" label="准成品库存量" sortable="custom"></el-table-column>
        <el-table-column header-align="center" width="166" align="center" prop="packMaterialSkuCount" label="包装物料数（SKU）"></el-table-column>
        <el-table-column header-align="center" width="166" align="center" prop="packMaterialInventory" label="包装物料库存量" sortable="custom"></el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="detailHandle(scope.row)">仓库库存详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :current-page.sync="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
        :total="total" layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
  import echarts from 'echarts'
  export default {
    name: 'warehouse',
    data () {
      return {
        tableLoading: false,
        tableData: [],
        dataForm: {
          order: '',
          orderField: ''
        },
        currentPage: 1,
        pageSize: 20,
        total: 0
      }
    },
    mounted () {
      this.getChartData()
      this.getTableData()
    },
    methods: {
      getTableData() {
        this.tableLoading = true
        this.$http.get('/statistics/tenantInventory/tablePage', {
          params: {
            page: this.currentPage,
            limit: this.pageSize,
            ...this.dataForm
          }
        }).then(({ data: res }) => {
          let { code, msg, data } = res
          if (code === 0 && data) {
            let { records, current, total} = data
            this.currentPage = current * 1
            this.total = total * 1
            this.tableData = records && records.length ? records : []
          }
          if (code !== 0) {
            this.$message.error(msg)
          }
          this.tableLoading = false
        })
      },
      getChartData () {
        return new Promise((resolve, reject) => {
          this.$http.get('/statistics/tenantInventory/getChartData').then(({ data: res }) => {
            let { code, msg, data } = res
            console.log(code, msg, data)
            if (code === 0) {
              this.mychart(data.productList, this.$refs.chart1, '产成品')
              this.mychart(data.semiManufactureList, this.$refs.chart2, '准成品')
              this.mychart(data.teaMaterialList, this.$refs.chart3, '散茶')
            }
            if (code !== 0) {
              reject('获取承运商订单状态统计数据失败')
            }
          })
        })
      },
      mychart (arr, dom, title) {
        arr.forEach(item => {
          item.name = item.tenantName
          item.value = item.quantity
        })
        let mychart = echarts.init(dom)
        mychart.setOption(
          {
            title: {
              text: title,
              left: 'lfet'
            },
            color: ['#3AA1FF', '#36CBCB', '#4ECB73', '#FBD437', '#F2637B'],
            tooltip: {
              trigger: 'item',
              formatter: `{b} </br> 占比 {d}%`
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                itemStyle: {
                  borderColor: '#fff',
                  borderWidth: 2
                },
                labelLine: {
                  length: 23
               },
               label: {
                  show: true,
                  formatter: '{b} : {c}'
                },
                data: arr
              }
            ]
          }
        )
      },
      sizeChangeHandle(pageSize) {
        this.pageSize = pageSize
        this.currentPage = 1
        this.getTableData()
      },
      currentChangeHandle(currentPage) {
        this.currentPage = currentPage
        this.getTableData()
      },
      detailHandle (row) {
        let { id } = row
        this.$router.push({
          name: 'tenantInventory-view',
          query: {
            id
          }
        })
      },
      sort_change (column) { // 自定义排序
        if (column.order === 'descending') { // 如果点击降序按钮
          this.dataForm.order = 'desc'
          this.dataForm.orderField = column.prop
        } else if (column.order === 'ascending') {
          this.dataForm.order = 'asc'
          this.dataForm.orderField = column.prop
        } else {
          this.$set(this.dataForm, 'order', '')
          this.$set(this.dataForm, 'orderField', '')
        }
        this.getTableData()
      },
      getSummaries (param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计'
            return
          }
          if (index > 1) {
            const values = data.map(item => Number(item[column.property]))
            let totalNum = 0
            totalNum = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] = totalNum
          }
          if (column.label === '操作') {
            sums[index] = ''
          }
        })
        return sums
      }
    }
  }
</script>

<style scoped>
  .warehouse {
    .el-pagination {
      margin-top: 6px;
    }
    .el-table {
      margin-top: 16px;
    }
  }
</style>